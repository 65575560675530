
//  Орехи\Грецкие орехи
// import walnut from '../components/Layers/Asortiment/Walnut/img/walnut__item_1.png';
import walnut_1 from '../components/Layers/Asortiment/Walnut/min/walnut__item_1.png'; // Им. Левина_01
import walnut_2 from '../components/Layers/Asortiment/Walnut/min/walnut__item_2.png'; // Казаку_02
import walnut_3 from '../components/Layers/Asortiment/Walnut/min/walnut__item_3.png'; // Кишиневский_03
import walnut_4 from '../components/Layers/Asortiment/Walnut/min/walnut__item_4.png'; // Костюжанский_04
import walnut_5 from '../components/Layers/Asortiment/Walnut/min/walnut__item_5.png'; // Милотай интенсив_05
import walnut_6 from '../components/Layers/Asortiment/Walnut/min/walnut__item_6.png'; // Песчанский_06
import walnut_7 from '../components/Layers/Asortiment/Walnut/min/walnut__item_7.png'; // Скиносский_07
import walnut_8 from '../components/Layers/Asortiment/Walnut/min/walnut__item_8.png'; // Фернор_08
import walnut_9 from '../components/Layers/Asortiment/Walnut/min/walnut__item_9.png'; // Чандлер_09

// Фундук
// import hazelnut from '../components/Layers/Asortiment/Hazelnut/img/hazelnut__item_1.png';
import hazelnut_1 from '../components/Layers/Asortiment/Hazelnut/min/hazelnut__item_1.png'; // Адыгейский_01
import hazelnut_2 from '../components/Layers/Asortiment/Hazelnut/min/hazelnut__item_2.png'; // Черкесский_02

// Яблони
// import applePear from '../components/Layers/Asortiment/Apple-Pear/img/apple-pear__item_1.png';
import applePear_1 from '../components/Layers/Asortiment/Apple-Pear/min/apple-pear__item_1.png'; // Супер-чиф
import applePear_2 from '../components/Layers/Asortiment/Apple-Pear/min/apple-pear__item_2.png'; // Яблоня Айдарет
import applePear_3 from '../components/Layers/Asortiment/Apple-Pear/min/apple-pear__item_3.png'; // Яблоня Гала
import applePear_4 from '../components/Layers/Asortiment/Apple-Pear/min/apple-pear__item_4.png'; // Яблоня Грани Смит
import applePear_5 from '../components/Layers/Asortiment/Apple-Pear/min/apple-pear__item_5.png'; // Яблоня Ред фри
import applePear_6 from '../components/Layers/Asortiment/Apple-Pear/min/apple-pear__item_6.png'; // Яблоня Чемпион


// Малина,Ежевика
// import raspberryBlackberry from '../components/Layers/Asortiment/Raspberry-Blackberry/img/raspberry-blackberry__item_1.png';
import raspberryBlackberry_1 from '../components/Layers/Asortiment/Raspberry-Blackberry/min/raspberry-blackberry__item_1.png'; // Ежевика Блек Меджик
import raspberryBlackberry_2 from '../components/Layers/Asortiment/Raspberry-Blackberry/min/raspberry-blackberry__item_2.png'; // Ежевика Честер Торнлесс
import raspberryBlackberry_3 from '../components/Layers/Asortiment/Raspberry-Blackberry/min/raspberry-blackberry__item_3.png'; // Малина Геракл ремонтантная
import raspberryBlackberry_4 from '../components/Layers/Asortiment/Raspberry-Blackberry/min/raspberry-blackberry__item_4.png'; // Малина Гигант Московский
import raspberryBlackberry_5 from '../components/Layers/Asortiment/Raspberry-Blackberry/min/raspberry-blackberry__item_5.png'; // Малина Полесье ремонтантная



//  Продукты Product

import product__item_1 from '../components/Layers/Product/min/product__item_1.png'; // Орех Чищенный
import product__item_2 from '../components/Layers/Product/min/product__item_2.png'; // Орехи не чищенные
import product__item_3 from '../components/Layers/Product/min/product__item_3.png'; // Чеснок не чищенный
import product__item_4 from '../components/Layers/Product/min/product__item_4.png'; // Чеснок чищеный
import product__item_5 from '../components/Layers/Product/min/product__item_5.png'; // Яблоки
import product__item_6 from '../components/Layers/Product/min/product__item_6.png'; // Ядра Орех
import product__item_7 from '../components/Layers/Product/min/product__item_7.png'; // Скорлупа Орех





const initState = {
    menu: [
        {
            title: "Грецкий орех",
            active: true,
            type: "walnut"
        },
        {
            title: "Фундук",
            type: "hazelnut"
        },
        {
            title: "Яблони/Груши",
            type: "apple-pear"
        },
        {
            title: "Малина/Ежевика",
            type: "raspberry-blackberry"
        }],
    items: [
        //  Грецкий орех ################################################
        {
            title: "Песчанский (Peschanscii)",
            type: "walnut",
            img: `${walnut_6}`,
            active: true,
            text: `
            <h3 class="goods-title">Песчанский (Peschanscii)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха Песчанский является эффективной разработкой Молдавских селекционеров. Саженцы данного сорта хорошо зарекомендовали себя в качестве посадочного материала для создания коммерческих садов в странах Молдовы, России, Украины, Румынии. Дерево ореха сорта Песчанский средних размеров с округлой кроной. Угол отхождения ветвей близкий к прямому, ветви располагаются довольно редко.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сроки цветения женских цветков - начало мая, что дает возможность уйти от ранневесенних заморозков. Цветение протогинического типа с разницей в 5-7 дней. Первыми цветут женские цветки, а затем мужские. Сорт частично самофертильный, так как нередко цветение мужских и женских цветов совпадает и происходит самооопыление. В качестве опылителей используют  такие сорта грецкого ореха как: Казаку, Скиносский, Когылничану, Костюжанский, Бричанский.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Песчанский отличаются очень высоким качеством и исключительно приятным десертным вкусом. Средний вес одного ореха - 13,5 г., из них 55-60 % составляет само ядро. Ядро плотное, обладает благородным кремовым цветом. Скорлупа у орехов  данного сорта тонкая, но при этом прочная. Ядро лёгко извлекается целиком. Выход ядра свыше 69%. Данный сорт используется преимущественно для механизированного извлечения ядра. Пригоден и для  реализации в скорлупе.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: 3 декада сентября – 1 декада октября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт грецкого ореха Песчанский - универсальный, пригоден для переработки и для употребления в свежем виде. Саженцы данного сорта обладают засухоустойчивостью и зимостойкостью. Легко переживает морозы до - 35 С.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Милотай интенсив (Milotai intensive)",
            type: "walnut",
            img: `${walnut_5}`,
            text: `
            <h3 class="goods-title">Милотай интенсив (Milotai intensive)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха венгерской селекции. Дерево среднерослое, хорошо растет как на суглинисто-карбонатных почвах, так и на черноземе.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Время цветения - вторая декада мая. Сорт Милотай высокоурожайный, имеет латеральный тип плодоношения. Самоопыляющийся сорт, для увеличения урожайности могут использоваться любые сорта.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Милотай крупные, диаметром 40 мм, весом от 12 г. Жирность ядра - 57%. Имеют праввильную овальную форму. Скорлупа тонкая и гладкая. Ядро светлое, обладает приятным вкусом.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: вторая декада октября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт грецкого ореха Милотай интенсив характеризуется высокой латеральностью, которая достигает 70%, что дает стабильный урожай около 4т/Га. Морозостойкость -25С. Хорошо храниться.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Фернор (Fernor)",
            type: "walnut",
            img: `${walnut_8}`,
            text: `
            <h3 class="goods-title">Фернор (Fernor)</h3><span class="ml-20"></span>
            <span class="ml-10">Фернор - это сорт грецкого ореха французской селекции. Саженцы сорта Фернор активно растут практически вертикально, что подходит для плотной схемы посадки.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сорт Фернор отличается поздним цветением. Этот сорт является самоопыляющимся. Для повышения урожайности рекомендуется использовать в качестве опылителя сорт Фернетте. Имеет латеральное плодоношение.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Фернор крупные, имеют удлиненную форму. Толщина скорлупы средняя, половинки крепко соединены между собой. Ядро обладает красивым кремовым цветом и сладким вкусом.  Данный сорт ореха предназначен для продажи в виде очищенных ядер.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: конец сентября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Высокая зимостойкость и урожайность.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Чандлер (Chandler)",
            type: "walnut",
            img: `${walnut_9}`,
            text: `
            <h3 class="goods-title">Чандлер (Chandler)</h3><span class="ml-20"></span>
            <span class="ml-10">Грецкий орех Чандлер, выведенный американскими селекционерами, популярен во всем мире. Дерево в процессе роста образует мощную, густую крону, ствол дерева крупный, может достигать 15 метров в высоту.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт грецкого ореха отличается поздним цветением. Не требует опылителя, так как является самоопыляемым. Дополнительно в качестве опылителя рекомендуется использование сорта Франкет. Имеет латеральное плодоношение.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды ореха Чандлер достаточно крупные, имеют прдолговатую форму. Средний вес плода равен 16 – 18 г. Ядро белого цвета, покрыто светлой кожицей. Скорлупа ореха тонкая, легко раскалывается, оставляя ядро целым. Орехи растут гроздьями. Сорт получил широкое использование в промышленных масштабах.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: первая декада сентября</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы грецкого ореха Чандлер обладают высокой приживаемостью, резистентны к морозам и многим заболеваниям. Данный сорт имеет очень ранний период начала плодоношения, отличается высокими показателями урожайности.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Скиносский (Scinoscii)",
            type: "walnut",
            img: `${walnut_7}`,
            text: `
            <h3 class="goods-title">Скиносский (Scinoscii)</h3><span class="ml-20"></span>
            <span class="ml-10">Дерево грецкого ореха сорта Скиносский имеет среднюю силу роста. Обладает среней степенью зимостойкости. В годы с повышенной влажностью может поражаться бурой пятнистостью.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Тип цветения протогонический. Женские соцветия распускаются на неделю раньше мужских. Оптимальные опылители - Казаку, Костюженский.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды ореха сорта Скиносский крупные, достигают в весе 10 - 12,5 г. Заполняемость ядра высокая. Орех Скиносский ценится за высокую маслянистость - содержание жира в нем достигает 70 %. Качество данного грецкого ореха соответствует Европейским стандартам качества.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: середина сентября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Деревья сорта Скиносский отличаются высокой продолжительностью жизни.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Костюженский (Kostugeni)",
            type: "walnut",
            img: `${walnut_4}`,
            text: `
            <h3 class="goods-title">Костюженский (Kostugeni)</h3><span class="ml-20"></span>
            <span class="ml-10">Саженец грецкого ореха сорта Костюженский является самым сильнорослым и быстрорастущим представителем молдавской селекции. Дерево среднерослое с густой кроной. Ветви прямые, имеют небольшой угол ветвления.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт грецкого ореха требует обязательного присутствия опылителей. В качестве опылителя используется сорт грецкого ореха Скиносский, так же возможно использование сорта Коглничану. Орех Костюженский отличается ранним протандрическим типом цветения, с опережающим цветением мужских цветов. Характеризуется классическим терминальным плодоношением.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Костюженский  крупные (14,5 г), почти округлой формы, слаборебристые. Ядро отделяется целиком, имеет белый цвет с желтоватым оттенком. Данный сорт используются преимущественно для реализации в скорлупе. Но это не исключает возможность его механической переработки и производства ядра.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: 3 декада сентября (через неделю после Казаку и Песчанский).</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы сорта грецкого ореха Костюженский отличается высокой приживаемостью, неприхотливостью в уходе, обильным плодоношением, повышенной устойчивостью к засухам, высокой резистентностью к бактериальным заболеваниям. Плоды ореха Костюженский обладают высокими товарными качествами.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Казаку (Kazaku)",
            type: "walnut",
            img: `${walnut_2}`,
            text: `
            <h3 class="goods-title">Казаку (Kazaku)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт молдавской селекции. Дерево сильнорослое, с высокоокруглой густой кроной и плотной древисиной. Саженцы не требуют особых условий посадки.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт грецкого ореха требует обязательного присутствия опылителя. В качестве опылителей рекомендуются сорта Костюженский и Калашарский. Тип цветения - протогеничный - женские цветки зацветают на 6-7 дней раньше мужских. Характеризуется терминальным типом плодоношения.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Ядро грецкого ореха Сорта Казаку белое, обладает очень приятным запахом, хорошо заполняет внутренность плода, легко извлекается и составляет около 60% от его массы. Размер самого плода относительно небольшой, вес 10 - 12 г. Скорлупа средней толщины, плотная, серого цвета. Сорт Казаку предназначен исключительно для производства ядра.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: данный сорт имеет ранние сроки созревания - конец первой декады сентября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Благодаря плотной древисине саженцы сорта Казаку обладают повышенной устойчивостью к зимним морозам, резистентны к бактериозу. Ядро грецкого ореха отличается высокими вкусовыми качествами.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Кишиневский (Khishynevskiy)",
            type: "walnut",
            img: `${walnut_3}`,
            text: `
            <h3 class="goods-title">Кишиневский (Khishynevskiy)</h3><span class="ml-20"></span>
            <span class="ml-10">Саженцы данного сорта грецкого ореха обладают средней силой роста, в вегетацию вступают на две неделе позже по сравнению с рано распускающимися сортами.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Отличительными особенностями данного сорта являются позднее цветение и самоопыляемость (женские и мужские цветы зацветают одновременно). Плодоношение относится к терминальному и подтерминальному типу.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды ореха данного сорта имеют средний размер, средний вес одного ореха около 10 г. Скорлупа тонкая, рыхлая, легко раздавливается. Ядро белого цвета, маслянистое, с насыщенным вкусом, составляет около 50% массы плода  и легко отделяется целиком.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы грецкого ореха сорта Кишиневский обладают высокой устойчивостью к поздним заморозкам, отличаются стабильным плодоношением, требуют минимального количества фитосанитарных обработок. Плоды грецкого ореха сорта Кишиневский благодаря позднему созреванию дольше сохраняют свежесть и, как следствие, товарные свойства, что является большим коммерческим преимуществом.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Им. Левина (Levin)",
            type: "walnut",
            img: `${walnut_1}`,
            text: `
            <h3 class="goods-title">Им. Левина (Levin)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха создан воронежским селекционером И. П. Левиным. Саженцы низкорослые, морозоустойчивые, с высокой приживаемостью. Каждый саженец с мощным стержневым корнем в виде "морковки" - диаметром 5 см, длиной от 25 см и более.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Имеет боковое плодоношение.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Левина имеют вес от 8 до 14 г. Скорлупа тонкая, легко раскалывается. Ядро составляет до 50 % плода.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы грецкого ореха сорта имени Левина отличаются высокой скороспелостью - первое плодоношение происходит уже на третий- четвертый год.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },

        // Фундук ################################################
        {
            title: "Черкесский 2",
            type: "hazelnut",
            img: `${hazelnut_2}`,
            text: `
            <h3 class="goods-title">Черкесский 2</h3><span class="ml-20"></span>
            <span class="ml-10">Орех сорта Черкесский 2 относится к сортам раннего срока созревания. Сорт обладает средней засухоустойчивостью. Куст сильнорослый с раскидистой кроной.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Средний вес ореха сорта Черкесский 2 составляет 1.6 г. Скорлупа тонкая, коричневая, со слегка выраженными продольными полосками. Выход ядра 45.2 %. Содержание жира около 70 %. Назначение плодов универсальное. Средняя урожайность с одного дерева составляет 10-12 кг орехов, в благоприятные годы - до 20 кг.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Фундук Черкесский 2 характеризуется устойчивостью к болезням и вредителям.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Адыгейский",
            type: "hazelnut",
            img: `${hazelnut_1}`,
            active: true,
            text: `
            <h3 class="goods-title">Адыгейский</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт фундука  Адыгейский относится к сортам раннего срока созревания. Зимостоек и засухоустойчив. Куст сильнорослый, может достигать до 5 метров в высоту. Крона раскидистая – в диаметре 4 метра.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Ранний срок созревания (конец августа).</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Качество ядра ореха высокое. Плоды имеют массу 2,6 г. У сорта отсутствуют все виды брака (гниль, сушеные ядра, ядра с повреждением поверхности, живые вредители), что и определяет большой выход ядра. По своей форме и качеству ядра ореха соответствуют спецификации на производство такого вида продукции, как ядра ореха фундука жареные, идеально подходит для кондитерской плитки шоколада. Ядра таких орехов после обжарки имеют равномерный цвет и требуют меньше затрат на доработку.  Скорлупа тонкая. Выход ядра 50 %, что соответствует спецификации, содержание жира около 70%. Форма ореха позволяет без существенных потерь переработать его на оборудовании.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Саженец  фундука сорта Адыгейский обладает высокой морозоустойчивостью, устойчивостью к засушливому климату, мало подвержен болезням и вредителям.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },

        // Яблони/Груши ################################################
        {
            title: "Супер Чиф",
            type: "apple-pear",
            img: `${applePear_1}`,
            active: true,
            text: `
            <h3 class="goods-title">Супер Чиф</h3><span class="ml-20"></span>
            <span class="ml-10">Очень урожайный американский зимний сорт яблонь. Дерево слаборослое. Относится к скороплодным и крупноплодным сортам. Характеризуется средней зимостойкостью. Плохо переносит температуру ниже 25 С.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Дерево сорта Супер Чиф вступает в плодоношение на 2- 3 год после посадки. Нуждается в опылителях, таких как Голден, Делишес, Фуджи. Плоды созревают в последней декаде сентября - начале октября.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды сорта Супер Чиф насыщенно красного цвета, крупные, удлиненной формы. Размер плода зависит от правильного  ухода за деревом. Средняя масса плода - 200 г. При правильном хранении пплоды сохраняют свои вкусовые качества на протяжении 7 месяцев.</span>
                    <div class="asortiment-box__desc">
                    <span class="ml-30">Высокие транспортабельные качества и лежкость плодов.</span>
                        </div>
                    </div>
                </div>
            </div> 
            `
        },
        {
            title: "Яблоня Гала",
            type: "apple-pear",
            img: `${applePear_3}`,
            text: `
            <h3 class="goods-title">Яблоня Гала</h3><span class="ml-20"></span>
            <span class="ml-10">Яблони сорта Гала относятся к среднерослым растениям. Отличаются поздним цветением (конец мая – начало июня), что оберегает формирующиеся завязи от поздних заморозков. Цветки большие, блюдцевидные, с крупными белоснежными лепестками. По отзывам опытных садоводов, деревья на обычных семенных подвоях (средней величины с овальной кроной) отличаются высокой скороплодностью и начинают приносить урожай на 6-7-й год. Растения высотой до 2 м, выращиваемые на карликовом подвое, дают урожай на 3-4-й год. Такие насаждения характеризуются большой устойчивостью к низким температурам и крупными плодами (от 140 до 170 г), но они требуют подвязки к опоре и постоянного кропотливого ухода. В нормальных условиях средние урожаи составляют до 55-80 кг с одного дерева.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">По срокам созревания сорт относится к осенним (конец сентября – начало октября), а по периоду потребления – к позднеосенним (ноябрь). До съемной зрелости яблоки прочно удерживаются на дереве. Сорт рекомендован  для коммерческого возделывания и промышленной переработки.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды средних размеров, массой – 130 г (115-145 г), максимально – до 170 г. Окраска при съеме желтая или зеленовато-желтая с полосатыми оранжево-красными разводами. Светло-желтая мякоть сочная и плотная. Внешняя привлекательность и вкус плодов не снижаются при транспортировке и длительном хранении. Сроки хранения в холодильнике – до февраля.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт яблок Гала отличается высокой и регулярной урожайностью. Имеет сильный иммунитет к мучнистой росе, а так же высокие показатели товарности, транспортабельности и лежкости плодов.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Яблоня Ред фри",
            type: "apple-pear",
            img: `${applePear_5}`,
            text: `
            <h3 class="goods-title">Яблоня Ред фри</h3><span class="ml-20"></span>
            <span class="ml-10">Дерево среднерослое. Максимальная высота — до 4 метров. Крона яблони очень раскидистая, но редкая, округлой формы. Сорт РедФри имеет устойчивость к низким температурам и подходит для выращивания в климатических условиях средней полосы России. По уровню зимостойкости данный сорт относят к средней группе. В северных регионах имеется риск повреждения от продолжительных морозов молодых растений и цветов в период весенних заморозков. Во влажные, прохладные годы саженцы могут подвергаться риску заболевания мучнистой росой.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Средняя урожайность составляет до 40 – 50 кг яблок с одного дерева. Максимальной урожайности яблоня достигает к семи – восьми годам.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды яблонь сорта РедФри обладают отличными товарными данными и вкусовыми характеристиками. Яблоки имеют зеленовато-желтый цвет с ярко-красным, чуть полосатым румянцем. Мякоть нежная, не очень плотная, сочная и ароматная. Кожица прочная, эластичная, устойчивая к повреждениям и сдавливанию, поэтому плоды хорошо транспортируются и хранятся в холодильнике до двух месяцев. Яблоки данного сорта ценятся за высокое содержание витаминов и микроэлементов.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Высокая урожайность и скороспелость яблонь сорта РедФри, что позволяют быстро окупить затраты на посадочный материал. У сорта РедФри имеется генетически обусловленнный иммунитет к парше, заложенный селекционерами на генном уровне.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Яблоня Айдарет",
            type: "apple-pear",
            img: `${applePear_2}`,
            text: `
            <h3 class="goods-title">Яблоня Айдарет</h3><span class="ml-20"></span>
            <span class="ml-10">Айдаред — высокоурожайный позднезимний сорт яблок, идеально подходяший для южных регионов. Сорт Айдаред (Idared) был выведен в США в первой половине прошлого века скрещиванием сортов Джонатан (Jonathan) и Вагнер (Wagener). До сих пор широко выращивается в Америке и Европе. В России преобдадает в Северо-Кавказском регионе и в Калининградской области. Деревья средней высоты, с шаровидной или широкопирамидальной кроной.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Плодоносить дерево яблони сорта Айдаред начинает через 4–6 лет после посадки. Урожай со взрослого дерева достигает 50–90 кг. Плоды созревают одновременно, почти не осыпаются. Сбор плодов проводится с конца сентября до середины октября.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды яблок сорта Айдаред крупные, массой 140–170 г. Вкус кисло-сладкий. Кожица тонкая.  Мякоть кремовая, сначала плотная и сочная, а при долгом хранении становится мягкой. Наиболее ярким вкус становится в январе. Окраска плодов очень привлекательная, светло-зелёная или зеленовато-жёлтая, с покрывающим почти весь плод ярко-малиновым румянцем. Яблоки Айдаред хорошо переносят транспортировку. Плоды хранятся очень долго, в простом погребе или подвале — до весны. В специальном холодильнике с контролируемой температурой (2°С) и влажностью (90–93%) могут храниться до середины лета, а иногда даже до нового урожая.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт Айдаред имеет универсального назначение. Его плоды одинаково хороши и свежими, и для приготовления выпечки и десертов, и для всех видов консервирования (сухофрукты, джемы, компоты, соки). Естественная кислинка яблок обеспечивает высокое качество консервов.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Яблоня Гренни Смит",
            type: "apple-pear",
            img: `${applePear_4}`,
            text: `
            <h3 class="goods-title">Яблоня Гренни Смит</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт яблок Гренни Смит много лет входит в десятку самых продаваемых сортов яблонь в мире. Данный сорт относится к полукарликовым сортам. Дерево обладает высокой силой роста, особенно в первые пять лет вегетации. Высота дерева достигает 3,5- 4,5 метров. Рост замедляется после вступления в период плодоношения. Корневая система обширная, но слабая. Яблони Гренни Смит плохо преносят холодный климат с продолжительной зимой. Оптимальной полосой для выращивания данного сорта является средняя полоса и юг России.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сорт ябок Гренни Смит относится к поздним. Яблони могут быть как самоопыляемыми, так и бесплодными. В качестве опылителей можно использовать следующие сорта: Лиголь, Делишес, Пинк Леди, Элиза. Созревание плодов происходит к концу сентября - началу октября. Яблони данного сорта считаются среднепродуктивными и достигают максимума урожайности на 5-7 год. С одного дерева можно собрать 20-30 кг плодов. Цикличность плодоношения регулярная. Продолжительность плодоношения составляет 8 - 10 лет.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Яблоко сорта Гренни Смит имеют насыщенный зеленый цвет. Масса плода достигает 180–200 г. Вкус сладко-кислый, аромат практически отсутствует. Яблочная мякоть остается плотной при термальной обработке, что делает этот сорт очень популярным в кулинарии. Благодаря малому содержанию сахара, яблоки сорта Гренни Смит можно отнести к диетическому продукту. Плоды хорошо преносят длительное хранение и транспортировку.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Яблоки сорта Гренни Смит обладают высокой лежкостью.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },

        {
            title: "Яблоня Чемпион",
            type: "apple-pear",
            img: `${applePear_6}`,
            text: `
            <h3 class="goods-title">Яблоня Чемпион</h3><span class="ml-20"></span>
            <span class="ml-10">Дерево яблони сорта Чемпион довольно компактное, а его высота напрямую зависит от системы подвоя. Подвой может быть карликовым и полукарликовым, тогда дерево будет достигать в высоту 2 - 3,5 м и заплодоносит уже на второй год после посадки. Если Чемпион привит на среднерослый подвой, то дерево вырастет более высоким – до 5 м, а даст первые плоды на третий год. Для выращивания этой яблони в условиях России используют зимостойкие подвои. Зимостойкость средняя.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сорт яблонь Чемпион относится к частично самоопыляемым и предполагает наличие в качестве опылителей яблонь другого сорта, например - Гала, Айдаред, Лобо и др.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды сорта Чемпион имеют желтый цвет с красно-оранжевым румянцем. Вкус сладкий. Вес плода может достигать 180 - 200 г. Транспортабельность плодов средняя. Лежкость урожая зависит от места его хранения: в холодильной камере – до 5 месяцев, в погребе – до 2 месяцев.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Скороплодность. Высокая урожайность.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },

        // Малина/Ежевика ################################################
        {
            title: "Малина Геракл ремонтантная",
            type: "raspberry-blackberry",
            img: `${raspberryBlackberry_3}`,
            text: `
            <h3 class="goods-title">Малина Геракл ремонтантная</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт малины Геракл рекомендован для выращивания в Центрально-Черноземном и Центральном регионах России. Куст даёт только 5-6 побегов, высота растения от 150 до 200 см. Побеги мощные, прямые, не склонные к сгибанию под весом ягод. Саженцы не требовательны в уходе.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Малина дает первый урожай уже в год посадки. Имеет две волны плодоношения: первый - урожай появляется на прошлогодних побегах, второй - на новых побегах. Ягоды продолжают созревать до первых заморозков. С одного куста при благоприятных погодных условиях и грамотной агротехнике удаётся получить до 10 кг ягод.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Ягоды малины конусообразные, яркого рубинового цвета. Их средний вес около 10 г. Отдельные самые большие ягоды достигают 15 г. Мякоть очень сочная. Вкус десертный кисло-сладкий. Аромат насыщенный. Транспортабельность сорта высокая, и ягоды при правильном хранении в течение длительного времени не теряют товарного вида.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Оптимально сажать малину Геракл осенью, чтобы уже предстоящим летом получить обильное плодоношение. Время высадки – октябрь, а на юге еще и ноябрь. Если высадить саженцы раньше, то из-за повышенных температур,они перенесут стресс и могут плохо перезимовать, также нельзя высаживать растение меньше, чем за месяц до возможных заморозков. Геракл должен успеть укорениться.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт отличается высокой урожайностью и хорошей транспортабельностью.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Малина Гигант Московский",
            type: "raspberry-blackberry",
            img: `${raspberryBlackberry_4}`,
            text: `
            <h3 class="goods-title">Малина Гигант Московский</h3><span class="ml-20"></span>
            <span class="ml-10">Малина Гигант Московский – это самый яркий представитель ягодной культуры, который пользуется большой популярностью среди садоводов. Московский Гигант относится к числу полуремонтантных сортов. Кусты Московского гиганта достаточно крупные. Высота одного стебля может быть 2-3 м. Куст обладает склонностью быстро загустевать, за счет активно растущих побегов, поэтому важно периодически проводить его обрезку. Данный сорт выращивается на территории всей страны, к тому же может произрастать даже в условиях сурового сибирского климата, ведь к числу его достоинств относится высокая зимостойкость.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт малины является самоопыляющимся. Плодоношение саженцев сорта Гигант Московский начинается в июле, и при благоприятных погодных условиях, может продолжаться практически до середины октября. Один ягодный куст Гиганта Московского может за сезон принести около 10-12 кг плодов.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Вес одной ягоды малины Гиганта Московского может достигать почти 30 г. Плоды красивой конической формы, ягода плотной консистенции.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Ягоды малины Гигант Московский имеют крупный размер. Для этго сорта характерна большая урожайность. На стеблях растения отсутствуют шипы, что облегчает сбор урожая. Самоопыляющийся сорт.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Малина Полесье ремонтантная",
            type: "raspberry-blackberry",
            img: `${raspberryBlackberry_5}`,
            text: `
            <h3 class="goods-title">Малина Полесье ремонтантная</h3><span class="ml-20"></span>
            <span class="ml-10">Ремонтантная малина сорта Полесье выведена в Польше в 2006 году. Сорт предназначен для фермерских и личных подсобных хозяйств. Популярность малины Полесье объясняется ее неприхотливостью, урожайностью и доступной стоимостью посадочного материала. При выращивании сорта имеет значение место посадки, естественное освещение, поступление влаги и полезных веществ. Под малинник выделяют участок, хорошо освещаемый солнцем и защищенный от ветра. Малину нельзя высаживать после картофеля, томатов и перцев. Среднерослое растение. Высота до 1,3 м.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт малины является ремонтантным. Созревание ягод происходит с конца июля до первых чисел октября. Спелые ягоды рекомендуется регулярно снимать. При длительном нахождении на побегах плоды темнеют. Урожайность с куста малины сорта Полесье за сезон может достигать 4,5 кг.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Ягода малины сорта Полесье ремонтантная имеет средний вес 6-10 г. Форма ягоды - сердцевидная. Цвет - темно-красный.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Плоды отличаются высокой транспортабельностью, подходят для заморозки.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Ежевика Блэк Мэджик",
            type: "raspberry-blackberry",
            img: `${raspberryBlackberry_1}`,
            active: true,
            text: `
            <h3 class="goods-title">Ежевика Блэк Мэджик</h3><span class="ml-20"></span>
            <span class="ml-10">Ежевика сорта Блэк Мэджик – ярко выраженная куманика. Ее побеги растут вверх и достигают 1,5 метров за один год, а за два года – не менее 2,5 метров. На побегах в районе плодоношения имеются небольшие и не слишком агрессивные шипы. На плодовых веточках шипы отсутствуют, что облегчает сбор урожая. Кусты ежевики обычно достаточно компактные, мощные ветви не нуждаются в опорах. Куст образует до 10 побегов замещения. Имеет невысокую морозоустойчивость.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Для ежевики этого сорта характерно раннее и очень быстрое начало вегетации. Так, цветение на побегах второго года начинается в двадцатых числах апреля, а на новых побегах – уже в конце июня – начале июля. Каждая кисть содержит от 7 до 12 крупных белых цветов, они прекрасно опыляются даже среди жаркого лета. Овальные крупные ягоды весят 6–7 г, полностью созревшие имеют черный цвет и глянцевый блеск. Урожайность на побегах первого года может достигать 1 кг. Побеги второго года жизни дают 4 кг ягод, через 3 года эти цифры удваиваются.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Ягоды ежевики сорта Блэк Мэджик мягкие, но достаточно плотные, не текут, легко отделяются от плодоножки, транспортабельны. Могут хорошо храниться несколько дней без потери формы, качества и вкуса после постепенного снижения температуры до +3 градусов. Ягоды чаще используются в свежем виде. Подходят так же для изготовления десертов и консервации.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Устойчивость сорта к грибковым заболеваниям.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Ежевика Честер Торнлесс (Chester Thornless)",
            type: "raspberry-blackberry",
            img: `${raspberryBlackberry_2}`,
            text: `
            <h3 class="goods-title">Ежевика Честер Торнлесс</h3><span class="ml-20"></span>
            <span class="ml-10">Гибридная бесшипная ежевика Честер Торнлесс (Chester Thornless) выведена в США. У ежевики Честер Торнлесс высокая побегообразовательная способность. Куст формирует полустелящиеся побеги длиной до 3 м. При желании их можно не подвязывать к шпалере, а разводить в разные стороны. Плодовые кисти тоже формируются невысоко от земли, чем обеспечивают высокую урожайность. Побеги лишены шипов. Корневая система разветвленная и мощная. Скороплодный сорт.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Плодоношение ежевики Честер Торнлесс имеет короткий цикл - 3-4 недели. Цветение в Средней полосе приходится на июнь. Ягоды созревают к началу августа, что считается среднепоздним сроком плодоношения. Почти во всех регионах они успевают поспеть до заморозков. Время сбора урожая ягод - начало августа, продолжается около месяца. Полноценный урожай сорт дает на третий год после посадки. Средняя урожайность сорта ежевики Честер Торнлесс – 10-15 кг, а при хорошей агротехнике – до 20 кг ягод с куста. Промышленные плантации дают до 30 т/га.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Сорт ежевики Честер Торнлесс относится к крупноплодным. Средний вес ягоды достигает 5-8 г. Плоды плотные, почти идеальной овальной формы, сине-черного цвета, красивые, в основном одномерные. Вкус у ежевики Честер Торнлесс сладкий.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Главными достоинствами ягод ежевики Честер Торнлесс является хорошая урожайность и высокое качество плодов. Они хорошо транспортируются и долго сохраняют товарные качества. Сорт Честер Торнлесс превосходит другую ежевику в устойчивости к морозам. Он способен выдержать температуру до -30⁰ С. Имеет высокую выносливость к жаре и засухе. Устойчив к болезням и вредителям.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },

    ],
    getItems: [
        //  Грецкий орех ################################################
        {
            title: "Песчанский (Peschanscii)",
            type: "walnut",
            img: `${walnut_6}`,
            active: true,
            text: `
            <h3 class="goods-title">Песчанский (Peschanscii)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха Песчанский является эффективной разработкой Молдавских селекционеров. Саженцы данного сорта хорошо зарекомендовали себя в качестве посадочного материала для создания коммерческих садов в странах Молдовы, России, Украины, Румынии. Дерево ореха сорта Песчанский средних размеров с округлой кроной. Угол отхождения ветвей близкий к прямому, ветви располагаются довольно редко.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сроки цветения женских цветков - начало мая, что дает возможность уйти от ранневесенних заморозков. Цветение протогинического типа с разницей в 5-7 дней. Первыми цветут  женские цветки, а затем мужские. Сорт частично самофертильный, так как нередко цветение  мужских и женских цветов совпадает и происходит самооопыление. В качестве опылителей используют  такие сорта грецкого ореха как: Казаку, Скиносский, Когылничану, Костюжанский, Бричанский.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Песчанский отличаются очень высоким качеством и исключительно приятным десертным вкусом. Средний вес одного ореха - 13,5 г., из них 55-60 % составляет само ядро. Ядро плотное, обладает благородным кремовым цветом. Скорлупа у орехов  данного сорта тонкая, но при этом прочная. Ядро лёгко извлекается целиком. Выход ядра свыше 69%. Данный сорт используется преимущественно для механизированного извлечения ядра. Пригоден и для реализации в скорлупе.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: 3 декада сентября – 1 декада октября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт грецкого ореха Песчанский - универсальный, пригоден для переработки и для употребления в свежем виде. Саженцы данного сорта обладают засухоустойчивостью и зимостойкостью. Легко переживает морозы до - 35 С.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Милотай интенсив (Milotai intensive)",
            type: "walnut",
            img: `${walnut_5}`,
            text: `
            <h3 class="goods-title">Милотай интенсив (Milotai intensive)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха венгерской селекции. Дерево среднерослое, хорошо растет как на суглинисто-карбонатных почвах, так и на черноземе.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Время цветения - вторая декада мая. Сорт Милотай высокоурожайный, имеет латеральный тип плодоношения. Самоопыляющийся сорт, для увеличения урожайности могут использоваться любые сорта.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Милотай крупные, диаметром 40 мм, весом от 12 г. Жирность ядра - 57%. Имеют праввильную овальную форму. Скорлупа тонкая и гладкая. Ядро светлое, обладает приятным вкусом.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: вторая декада октября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт грецкого ореха Милотай интенсив характеризуется высокой латеральностью, которая достигает 70%, что дает стабильный урожай около 4т/Га. Морозостойкость -25С. Хорошо храниться.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Фернор (Fernor)",
            type: "walnut",
            img: `${walnut_8}`,
            text: `
            <h3 class="goods-title">Фернор (Fernor)</h3><span class="ml-20"></span>
            <span class="ml-10">Фернор - это сорт грецкого ореха французской селекции. Саженцы сорта Фернор активно растут практически вертикально, что подходит для плотной схемы посадки.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сорт Фернор отличается поздним цветением. Этот сорт является самоопыляющимся. Для повышения урожайности рекомендуется использовать в качестве опылителя сорт Фернетте. Имеет латеральное плодоношение.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Фернор крупные, имеют удлиненную форму. Толщина скорлупы средняя, половинки крепко соединены между собой. Ядро обладает красивым кремовым цветом и сладким вкусом.  Данный сорт ореха предназначен для продажи в виде очищенных ядер.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: конец сентября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Высокая зимостойкость и урожайность.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Чандлер (Chandler)",
            type: "walnut",
            img: `${walnut_9}`,
            text: `
            <h3 class="goods-title">Чандлер (Chandler)</h3><span class="ml-20"></span>
            <span class="ml-10">Грецкий орех Чандлер, выведенный американскими селекционерами, популярен во всем мире. Дерево в процессе роста образует мощную, густую крону, ствол дерева крупный, может достигать 15 метров в высоту.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт грецкого ореха отличается поздним цветением. Не требует опылителя, так как является самоопыляемым. Дополнительно в качестве опылителя рекомендуется использование сорта Франкет. Имеет латеральное плодоношение.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды ореха Чандлер достаточно крупные, имеют прдолговатую форму. Средний вес плода равен 16 – 18 г. Ядро белого цвета, покрыто светлой кожицей. Скорлупа ореха тонкая, легко раскалывается, оставляя ядро целым. Орехи растут гроздьями. Сорт получил широкое использование в промышленных масштабах.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: первая декада сентября</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы грецкого ореха Чандлер обладают высокой приживаемостью, резистентны к морозам и многим заболеваниям. Данный сорт имеет очень ранний период начала плодоношения, отличается высокими показателями урожайности.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Скиносский (Scinoscii)",
            type: "walnut",
            img: `${walnut_7}`,
            text: `
            <h3 class="goods-title">Скиносский (Scinoscii)</h3><span class="ml-20"></span>
            <span class="ml-10">Дерево грецкого ореха сорта Скиносский имеет среднюю силу роста. Обладает среней степенью зимостойкости. В годы с повышенной влажностью может поражаться бурой пятнистостью.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Тип цветения протогонический. Женские соцветия распускаются на неделю раньше мужских. Оптимальные опылители - Казаку, Костюженский.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды ореха сорта Скиносский крупные, достигают в весе 10 - 12,5 г. Заполняемость ядра высокая. Орех Скиносский ценится за высокую маслянистость - содержание жира в нем достигает 70 %. Качество данного грецкого ореха соответствует Европейским стандартам качества.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: середина сентября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Деревья сорта Скиносский отличаются высокой продолжительностью жизни.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Костюженский (Kostugeni)",
            type: "walnut",
            img: `${walnut_4}`,
            text: `
            <h3 class="goods-title">Костюженский (Kostugeni)</h3><span class="ml-20"></span>
            <span class="ml-10">Саженец грецкого ореха сорта Костюженский является самым сильнорослым и быстрорастущим представителем молдавской селекции. Дерево среднерослое с густой кроной. Ветви прямые, имеют небольшой угол ветвления.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт грецкого ореха требует обязательного присутствия опылителей. В качестве опылителя используется сорт грецкого ореха Скиносский, так же возможно использование сорта Коглничану. Орех Костюженский отличается ранним протандрическим типом цветения, с опережающим цветением мужских цветов. Характеризуется классическим терминальным плодоношением.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Костюженский  крупные (14,5 г), почти округлой формы, слаборебристые. Ядро отделяется целиком, имеет белый цвет с желтоватым оттенком. Данный сорт используются преимущественно для реализации в скорлупе. Но это не исключает возможность его механической переработки и производства ядра.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: 3 декада сентября (через неделю после Казаку и Песчанский).</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы сорта грецкого ореха Костюженский отличается высокой приживаемостью, неприхотливостью в уходе, обильным плодоношением, повышенной устойчивостью к засухам, высокой резистентностью к бактериальным заболеваниям. Плоды ореха Костюженский обладают высокими товарными качествами.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Казаку (Kazaku)",
            type: "walnut",
            img: `${walnut_2}`,
            text: `
            <h3 class="goods-title">Казаку (Kazaku)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт молдавской селекции. Дерево сильнорослое, с высокоокруглой густой кроной и плотной древисиной. Саженцы не требуют особых условий посадки.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Данный сорт грецкого ореха требует обязательного присутствия опылителя. В качестве опылителей рекомендуются сорта Костюженский и Калашарский. Тип цветения - протогеничный - женские цветки зацветают на 6-7 дней раньше мужских. Характеризуется терминальным типом плодоношения.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Ядро грецкого ореха Сорта Казаку белое, обладает очень приятным запахом, хорошо заполняет внутренность плода, легко извлекается и составляет около 60% от его массы. Размер самого плода относительно небольшой, вес 10 - 12 г. Скорлупа средней толщины, плотная, серого цвета. Сорт Казаку предназначен исключительно для производства ядра.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: данный сорт имеет ранние сроки созревания - конец первой декады сентября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Благодаря плотной древисине саженцы сорта Казаку обладают повышенной устойчивостью к зимним морозам, резистентны к бактериозу. Ядро грецкого ореха отличается высокими вкусовыми качествами.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Кишиневский (Khishynevskiy)",
            type: "walnut",
            img: `${walnut_3}`,
            text: `
            <h3 class="goods-title">Кишиневский (Khishynevskiy)</h3><span class="ml-20"></span>
            <span class="ml-10">Саженцы данного сорта грецкого ореха обладают средней силой роста, в вегетацию вступают на две неделе позже по сравнению с рано распускающимися сортами.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Отличительными особенностями данного сорта являются позднее цветение и самоопыляемость (женские и мужские цветы зацветают одновременно). Плодоношение относится к терминальному и подтерминальному типу.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды ореха данного сорта имеют средний размер, средний вес одного ореха около 10 г. Скорлупа тонкая, рыхлая, легко раздавливается. Ядро белого цвета, маслянистое, с насыщенным вкусом, составляет около 50% массы плода  и легко отделяется целиком.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы грецкого ореха сорта Кишиневский обладают высокой устойчивостью к поздним заморозкам, отличаются стабильным плодоношением, требуют минимального количества фитосанитарных обработок. Плоды грецкого ореха сорта Кишиневский благодаря позднему созреванию дольше сохраняют свежесть и, как следствие, товарные свойства, что является большим коммерческим преимуществом.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Им. Левина (Levin)",
            type: "walnut",
            img: `${walnut_1}`,
            text: `
            <h3 class="goods-title">Им. Левина (Levin)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха создан воронежским селекционером И. П. Левиным. Саженцы низкорослые, морозоустойчивые, с высокой приживаемостью. Каждый саженец с мощным стержневым корнем в виде "морковки" - диаметром 5 см, длиной от 25 см и более.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Имеет боковое плодоношение.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Левина имеют вес от 8 до 14 г. Скорлупа тонкая, легко раскалывается. Ядро составляет до 50 % плода.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Саженцы грецкого ореха сорта имени Левина отличаются высокой скороспелостью - первое плодоношение происходит уже на третий- четвертый год.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },

    ],
    getItemId: {
        title: "Песчанский (Peschanscii)",
        type: "walnut",
        img: `${walnut_6}`,
        active: true,
        text: `
        <h3 class="goods-title">Песчанский (Peschanscii)</h3><span class="ml-20"></span>
            <span class="ml-10">Сорт грецкого ореха Песчанский является эффективной разработкой Молдавских селекционеров. Саженцы данного сорта хорошо зарекомендовали себя в качестве посадочного материала для создания коммерческих садов в странах Молдовы, России, Украины, Румынии. Дерево ореха сорта Песчанский средних размеров с округлой кроной. Угол отхождения ветвей близкий к прямому, ветви располагаются довольно редко.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сроки цветения женских цветков - начало мая, что дает возможность уйти от ранневесенних заморозков. Цветение протогинического типа с разницей в 5-7 дней. Первыми цветут  женские цветки, а затем мужские. Сорт частично самофертильный, так как нередко цветение  мужских и женских цветов совпадает и происходит самооопыление. В качестве опылителей используют  такие сорта грецкого ореха как: Казаку, Скиносский, Когылничану, Костюжанский, Бричанский.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Плоды грецкого ореха сорта Песчанский отличаются очень высоким качеством и исключительно приятным десертным вкусом. Средний вес одного ореха - 13,5 г., из них 55-60 % составляет само ядро. Ядро плотное, обладает благородным кремовым цветом. Скорлупа у орехов  данного сорта тонкая, но при этом прочная. Ядро лёгко извлекается целиком. Выход ядра свыше 69%. Данный сорт используется преимущественно для механизированного извлечения ядра. Пригоден и для реализации в скорлупе.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Сроки созревания и уборки: 3 декада сентября – 1 декада октября.</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-30">Сорт грецкого ореха Песчанский - универсальный, пригоден для переработки и для употребления в свежем виде. Саженцы данного сорта обладают засухоустойчивостью и зимостойкостью. Легко переживает морозы до - 35 С.
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        `
    },






    // Отдельные продукты на странице "Продукция" ############################################
    getItemSelect: [
        {
            title: "Грецкий орех в скорлупе",
            img: `${product__item_2}`,
            active: true,
            text: ` 
            <h3 class="goods-title">Грецкий орех в скорлупе</h3><span class="ml-20"></span>
            <span class="ml-10">Страна производства: РФ, Черноземье.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сбор нового урожая: сентябрь.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Средний вес одного ореха – 14 г. Скорлупа тонкая. Ядро плотное. Цвет ядра светлый с кремовым оттенком. Вкус – насыщенный, десертный. Ядро легко извлекается целиком без нарушения целостности. Выход ядра - свыше 60%.</span>
                        </div>
                    </div>
                </div>
            </div> 
            `
        },
        {
            title: "Ядра грецкого ореха",
            img: `${product__item_1}`,

            text: `
            <h3 class="goods-title">Ядра грецкого ореха</h3><span class="ml-20"></span>
            <span class="ml-10">Половина ядра грецкого ореха (бабочка) - получается в результате деления ядра на две одинаковые и цельные части.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Четверть грецкого ореха - результат деления ядра вдоль на четыре одинаковые части.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Крупные кусочки грецкого ореха - части ядра, размер которых меньше четверти целого ядра, но не менее 8 мм.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Мелкие кусочки грецкого ореха - части ядра размером до 3 мм.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Перегородки грецкого ореха",
            img: `${product__item_6}`,
            text: `
            <h3 class="goods-title">Перегородки грецкого ореха</h3><span class="ml-20"></span>
            <span class="ml-10">Перегородки грецкого ореха - это уникальный природный продукт, который обладает большим набором полезных веществ. Самый важный и полезный элемент перегородок грецкого ореха – это йод. Отвары на основе перегородок йода позволяют восполнить дефицит йода в организме человека и устранить многие симптомы, связанные с недостатком этого микроэлемента. Перегородки грецкого ореха используют для приготовления других различных настоек. Сфера применения ореховых перегородок в медицинской практике очень разнообразна, а польза весьма ощутима. Широкое применения перегородки грецкого ореха нашли и в различных производствах, в том числе в самогоноварении. Двухнедельное настаивание этого напитка на перегородках грецкого ореха придает ему мягкость, убирает характерный привкус и делает вкус ароматным.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Скорлупа грецкого ореха",
            img: `${product__item_7}`,
            text: `
            <h3 class="goods-title">Скорлупа грецкого ореха</h3><span class="ml-20"></span>
            <span class="ml-10">Скорлупа грецкого ореха - скорлупа грецкого ореха так же, как и его ядро, содержит большое количество полезных для организма человека веществ. В составе скорлупы ореха грецкого много клетчатки (60%), содержится, цинк, магний, йод, калий, железо, протеины, стероиды, аскорбиновая и карбоновая кислота, витамины Е, В, С, А. Скорлупа грецкого ореха используется в традиционной и нетрадиционной медицине для лечения дисфункций щитовидной железы, гастритов, заболеваний сердечной системы, заживления ран и ушибов и т.п.; в косметологии, стоматологии, диетологии. Скорлупу грецкого ореха успешно применяют в сельском хозяйстве.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Чеснок не чищеный",
            img: `${product__item_3}`,
            text: `
            <h3 class="goods-title">Товарный чеснок</h3><span class="ml-20"></span>
            <span class="ml-10">Чеснок Любаша – это крупный озимый сорт украинской селекции. Описание: головки чеснока крупные - средний вес составляет 100-120 г. Цвет оболочки белый. Количество зубков 4-7шт., средний вес одного зубка -15-20 г. Окраска зубков белая с нежнейшим оттенком розового или кремового. Шкурка зубков окрашена фиолетовыми полосками на белом фоне. Химический состав чеснока сорта Любаша отмечен высоким содержанием витамина С (до 34 %). Сроки хранения – до 11 месяцев месяцев без потери качества. Назначение – универсальное. Чеснок сорта Любаша одинаково хорошо подходит для приготовления соусов, горячих блюд, домашних заготовок и маринадов.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Посевной материал (первой генерации):</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Сорт чеснока Любаша крупноплодный, устойчивый к морозам и весенним похолоданиям. Обладает высоким иммунитетом ко многим грибковым заболеваниям, редко подвергается нападкам вредителей. Нетребователен к поливу. Начало сбора урожая – вторая декада июля. Урожайность - от 3,5 до 10 т с 1 га в зависимости от условий выращивания.</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-30">Посадочный материал представлен следующими вариантами:</span>
                        <div class="asortiment-box__desc">
                        <span class="ml-10">- воздушная бульбочка;
                        </span>
                        <div class="asortiment-box__desc">
                    <span class="ml-10">- однозубка, калибр 1,5-2 см; 2-3 см; 3 см и выше;</span>
                    <div class="asortiment-box__desc">
                        <span class="ml-10">- зубки.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Чеснок чищенный",
            img: `${product__item_4}`,
            text: `
            <h3 class="goods-title">Чеснок чищенный</h3><span class="ml-20"></span>
            <span class="ml-10">Чеснок очищенный в вакуумном пакете. Средний вес одного зубка 15-20 г. Фасовка от 1 кг до 10 кг.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
        {
            title: "Яблоки",
            img: `${product__item_5}`,
            text: `
            <h3 class="goods-title">Яблоки</h3><span class="ml-20"></span>
            <span class="ml-10">Поставка яблок оптом от 10 тонн.</span>
                        </div>
                    </div>
                </div>
            </div>
            `
        },
    ],

    // По умолчанию выводим первый продукт
    getItemsSelectId:
    {
        title: "Грецкий орех в скорлупе",
        img: `${product__item_2}`,
        active: true,
        text: ` 
        <h3 class="goods-title">Грецкий орех в скорлупе</h3><span class="ml-20"></span>
            <span class="ml-10">Страна производства: РФ, Черноземье.</span>
            <div class="asortiment-box__desc">
                <span class="ml-30">Сбор нового урожая: сентябрь.</span>
                <div class="asortiment-box__desc">
                    <span class="ml-30">Средний вес одного ореха – 14 г. Скорлупа тонкая. Ядро плотное. Цвет ядра светлый с кремовым оттенком. Вкус – насыщенный, десертный. Ядро легко извлекается целиком без нарушения целостности. Выход ядра - свыше 60%.</span>
                        </div>
                    </div>
                </div>
            </div>
        `
    },
}






const assort = (state = initState, action) => {

    switch (action.type) {

        case 'SET_MARK':

            state.menu.map((item, i) => {

                item.active = false;

                if (action.param === i) {
                    item.active = true;
                }
            });

            return {
                ...state,
                state
            }

        case 'GET_PROD_ITEM':

            //  Очищаем item от active class
            state.items.map((item, i) => {

                item.active = false;
            });

            const items = state.items.filter((item, i) => {

                if (item.type === action.param) {
                    return item;
                }
            });

            //  При переключении в меню "Ассортимент" 
            items[0].active = true

            return {
                ...state,
                getItems: items,
                getItemId: items[0]
            }

        case 'GET_PROD_ITEM_ID':

            //  Очищаем item от active class
            state.getItems.map((item, i) => {

                if (item.title === action.param) {
                    return item.active = true;
                }

                item.active = false;
            });

            //  Очищаем item от active class
            state.items.map((item, i) => {

                item.active = false;
            });

            const itemId = state.items.find((item, i) => {

                if (item.title === action.param) {

                    return item.active = true;
                }
            });

            return {
                ...state,
                getItems: state.getItems,
                getItemId: itemId
            }


        case 'GET_PROD_SELECT':

            //  Очищаем item от active class
            state.getItemSelect.map((item, i) => {

                item.active = false;
            });

            const itemSelect = state.getItemSelect.find((item, i) => {

                if (item.title === action.param) {
                    return item.active = true;
                }
            });

            return {
                ...state,
                getItemsSelectId: itemSelect,
            }
    }

    return {
        ...state
    }
}

export default assort;